import React from 'react'
import { CenterWrapper, Logo, Link, CenterWrapperProps } from '@/components'
import { DrawerMenu } from './Drawer'
import { NavContent } from './Content'
import { useMediaQueryDown } from '@/utils'
import { StyleSheets, StyleRegistry } from '@/config'
import { Drawer, useStylesFor } from '@codeleap/web'
import { StyledProp, StyledComponent } from '@codeleap/styles'
import { PropsOf } from '@codeleap/common'

export type HeaderProps = Omit<CenterWrapperProps, 'style'> & {
  style?: StyledProp<StyleSheets.HeaderComposition>
  drawerProps?: PropsOf<typeof Drawer>
}

const HeaderComponent: StyledComponent<
  typeof StyleSheets.HeaderStyles,
  HeaderProps
> = props => {
  const { drawerProps, style, ...rest } = props

  const isMobile = useMediaQueryDown('tabletSmall')

  const styles = useStylesFor(HeaderComponent.styleRegistryName, style)

  return (
    <CenterWrapper
      {...rest}
      style={{
        wrapper: styles.wrapper,
        innerWrapper: styles.innerWrapper,
      }}
    >
      <Link route="Home" style={styles.logoWrapper}>
        <Logo debugName="header:logo" style={styles.logo} />
      </Link>

      {/* {isMobile ? (
        <DrawerMenu
          {...drawerProps}
          styles={styles}
          isMobile={isMobile}
        />
      ) : (
        <NavContent
          styles={styles}
          isMobile={isMobile}
        />
      )} */}
    </CenterWrapper>
  )
}

HeaderComponent.styleRegistryName = 'Header'

HeaderComponent.elements = [
  'wrapper',
  'innerWrapper',
  'drawer',
  'signInButton',
  'menuIcon',
  'navContentWrapper',
  'navItem',
  'navItem:selected',
  'logo',
  'profile',
  'firstName',
  'email',
  'avatar',
]

StyleRegistry.registerComponent(HeaderComponent)

export const Header = React.memo(HeaderComponent, () => true)
