import { api } from '@/config'
import { OptimumChatMessage } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { v4 as uuidv4 } from 'uuid'
import { bubbleDelay } from '@/utils'

const BASE_URL = 'optimum/chat/'

type AskBody = {
  message: string
  thread_ref: string
}

type AskResponse = {
  user_message?: OptimumChatMessage
  assistant_messages?: OptimumChatMessage[]
}

export async function ask(body: AskBody) {
  const response = await api.post<AskResponse>(BASE_URL + 'ask/', body)
  return response.data
}

export const messagesManager = new QueryManager({
  itemType: {} as OptimumChatMessage,
  name: 'optmum_chat_messages',
  queryClient: queryClient.client,
  listItems: async (limit, offset, filters: { thread_ref: string }) => {
    const response = await api.get<PaginationResponse<OptimumChatMessage>>(
      BASE_URL + 'messages/',
      {
        params: {
          limit,
          offset,
          ...filters,
        },
      }
    )
    return response.data
  },
  actions: {
    async askOptimum(
      manager,
      { body, onSuccess }: { body: AskBody; onSuccess?: () => void }
    ): Promise<AskResponse> {
      const randomNumber = uuidv4()

      const optimisticMessage = {
        role: 'user',
        id: randomNumber,
        message: body.message,
        created_datetime: new Date(),
      } as OptimumChatMessage

      manager.addItem({ to: 'start', item: optimisticMessage })
      const response = await ask(body)
      onSuccess?.()

      if (response.user_message) {
        const messagesList = Object.values(manager.itemMap)
        let newItems = [...messagesList]
        const optimisticMessageIndex = newItems.findIndex(
          item => item?.id === optimisticMessage?.id
        )
        newItems.splice(optimisticMessageIndex, 1)
        newItems.push(response?.user_message)
        manager.updateItems({ ...newItems })
      }

      if (!!response.assistant_messages?.length)
        for (const m of response.assistant_messages) {
          manager.addItem({
            to: 'start',
            item: {
              ...m,
              isNew: true,
            },
          })
          const delay = bubbleDelay(m.message)
          await new Promise(resolve => setTimeout(resolve, delay))
        }

      return response
    },
  },
})
