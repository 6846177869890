import { AppImages, navigation, React, theme } from '@/config'
import { View, Text, Avatar, Button, Image } from '@/components'

type OptimumCardProps = {
  origin: 'Exercise' | 'Nutrition'
}

export const OptimumCard = ({ origin = 'Nutrition' }: OptimumCardProps) => {
  return (
    <View
      style={[
        'column',
        'margin:2',
        'padding:2',
        'borderRadius:small',
        { position: 'relative' },
      ]}
    >
      <Image
        alt="OptimumCardBG"
        source={AppImages.OptimumCardBG}
        objectFit="cover"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          borderRadius: 8,
        }}
      />

      <View>
        <Avatar
          debugName="Optimum Avatar"
          image={AppImages.OptimumAvatar2}
          style={{ border: '2px solid white', borderRadius: 9999 }}
        />
        <View style={['column', 'marginLeft:1', { zIndex: 1 }]}>
          <Text
            text="Hello, I’m Coach Optimum"
            style={['p1', 'color:neutral1', 'regular']}
          />
          <Text
            text="Powered by AI technology"
            style={['p4', 'color:neutral1', , { fontWeight: '400' }]}
          />
        </View>
      </View>

      <View
        style={[
          'backgroundColor:primary1',
          'marginTop:2',
          'padding:2',
          'borderRadius:small',
          'column',
          'marginBottom:3',
          { zIndex: 1 }
        ]}
      >
        <Text
          text={`Today's message`}
          style={['p3', 'color:primary7', 'semiBold', 'marginBottom:0.5']}
        />
        <Text
          text={`Today is rest day. Finally! You've been a rockstar lately. Why not take the time to create your new workout plan?`}
          style={['p3', 'color:neutral10', 'regular']}
        />
      </View>

      <View style={['gap:1']}>
        <Button
          debugName='recommendations button'
          style={[
            'small',
            'centerLeftIcon',
            { flex: 1, icon: { color: theme.colors.primary6 } },
          ]}
          text={`${
            origin === 'Nutrition' ? 'Recipe' : 'Sports'
          } recommendations`}
          icon="help-circle"
        />
        <Button
          debugName='ask coach button'
          style={[
            'small',
            'centerLeftIcon',
            { flex: 0.6, icon: { color: theme.colors.primary6 } },
          ]}
          text={'Ask Coach'}
          icon="help-circle"
          onPress={() => navigation.navigate('Home')}
        />
      </View>
    </View>
  )
}
