import { AppImages, React } from '@/config'
import { EmptyPlaceholder } from '@/components'
import { QueryManager, QueryManagerItem } from '@codeleap/common'
import { PaginationIndicator } from '@codeleap/web'

type Options = {
  noMoreItemsText?: string
  forceLoading?: boolean
  forceHasNextPage?: boolean
}

export const getFlatlistProps = <
  TItem extends QueryManagerItem,
  T extends QueryManager<any, any, any, any> = QueryManager<any, any, any, any>
>(
  hookReturn: ReturnType<T['use']> | ReturnType<T['useList']>,
  options: Options = {}
) => {
  const {
    noMoreItemsText = 'No more items to show',
    forceHasNextPage = false,
    forceLoading = false,
  } = options

  // @ts-ignore
  const listQuery = !!hookReturn?.list
    ? (hookReturn?.list?.query as unknown)
    : hookReturn?.query

  const hasMore = listQuery?.isLoading || listQuery?.hasNextPage

  const showPaginationIndicator = listQuery?.isFetchingNextPage || !hasMore

  const ListPaginationIndicator = !showPaginationIndicator
    ? null
    : ({ isEmpty = false }) => {
        if (isEmpty) return null

        return (
          <PaginationIndicator
            isFetching={listQuery?.isFetchingNextPage || forceHasNextPage}
            noMoreItemsText={noMoreItemsText}
            hasMore={hasMore}
          />
        )
      }

  const pagesLength = listQuery?.data?.pages?.length
  const currentNext = listQuery?.data?.pages?.[pagesLength - 1]?.next

  return {
    data: hookReturn.items as TItem[],
    fetchNextPage: () => {
      if (!hasMore || !hookReturn?.items?.length) return
      hookReturn.getNextPage?.()
    },
    currentNext,
    keyExtractor: item => item?.id,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    loading: forceLoading || listQuery?.isLoading,
    ListFooterComponent: ListPaginationIndicator,
    isFetchingNextPage: listQuery?.isFetchingNextPage,
    ListLoadingIndicatorComponent: () => null,
    ListEmptyComponent: props => (
      <EmptyPlaceholder
        {...props}
        title="No items"
        description="Nothing to see here for now..."
      />
    ),
    hasNextPage: hasMore,
    isLoading: listQuery?.isLoading,
    placeholder: {
      loading:
        (listQuery.isFetching || listQuery?.isLoading || listQuery?.loading) &&
        !listQuery?.isRefreshing,
      image: AppImages.EmptyBox as unknown as string,
    },
    showPaginationIndicator,
  }
}

export const onScrollNearTopReversed = (
  scrollEvent,
  onNearTopReached = () => {},
  threshold = 0
) => {
  const { scrollTop, scrollHeight, clientHeight } = scrollEvent.target
  const fullHeight = scrollHeight - clientHeight

  if (fullHeight + scrollTop <= threshold) {
    onNearTopReached?.()
  }
}
