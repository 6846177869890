import React, { useState } from 'react'
import { View, TextInput, ActionIcon } from '@/components'
import { MAX_APP_WIDTH, theme } from '@/config'
import { useWindowSize } from '@codeleap/web'
import { createStyles } from '@codeleap/styles'

export type OptimumChatFooterProps = {
  onSendMessage: (message: string) => void
  disabled?: boolean
}

export const OptimumChatFooter = ({
  onSendMessage,
  disabled,
}: {
  onSendMessage: (message: string) => void
  disabled: boolean
}) => {
  const [width, _] = useWindowSize()
  const windowWidth = width > MAX_APP_WIDTH ? MAX_APP_WIDTH : width
  const [message, setMessage] = useState('')

  const handleSendMessage = () => {
    if (!message || disabled) return
    onSendMessage(message)
    setMessage('')
  }

  const inputCollapsed =
    windowWidth - (theme.spacing.value(6) + theme.values.itemHeight.small)

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return
      } else {
        event.preventDefault()
        handleSendMessage()
      }
    }
  }

  return (
    <View style={styles.inputWrapper}>
      <TextInput
        debugName="Optimum chat input"
        placeholder="Message"
        style={[
          'optimumChat',
          'noError',
          {
            minWidth: !!message ? inputCollapsed : '100%',
            transition: 'min-width 0.3s ease',
          },
        ]}
        onChangeText={setMessage}
        value={message}
        multiline
        onKeyDown={handleKeyDown}
      />

      <View
        style={[
          'marginLeft:1',
          {
            position: 'relative',
            left: !!message ? 0 : 60,
            opacity: disabled ? 0.3 : 1,
            transition: 'left 0.3s ease, loading 0.3s ease',
          },
        ]}
      >
        <ActionIcon
          debugName="Send Message"
          name="arrow-up"
          disabled={!message || disabled}
          onPress={handleSendMessage}
        />
      </View>
    </View>
  )
}

const styles = createStyles(theme => ({
  inputWrapper: {
    display: 'flex',
    marginTop: 'auto',
    ...theme.presets.fullWidth,
    backgroundColor: theme.colors.neutral2,
    ...theme.presets.alignCenter,
    ...theme.spacing.paddingTop(1),
    ...theme.spacing.paddingHorizontal(2),
    ...theme.spacing.paddingBottom(2),
    borderTop: `1px solid ${theme.colors.neutral3}`,
  },
}))
