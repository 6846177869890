const config = {
  apiKey: "AIzaSyCaURlyAsJR6ZdQccGkKIQdKQvDgf-gfvk",
  authDomain: "x-optimum-nutrition-7ee70.firebaseapp.com",
  projectId: "x-optimum-nutrition-7ee70",
  storageBucket: "x-optimum-nutrition-7ee70.appspot.com",
  messagingSenderId: "971615670688",
  appId: "1:971615670688:web:1e3a273ed773e2566c14c5"
}

import * as _firebase from 'firebase/app';
import * as auth from 'firebase/auth';

type WithoutFirstArg<FN> = FN extends (arg: any, ...rest: infer R) => any ? (...args: R) => ReturnType<FN> : FN
type FirstArg<FN> = FN extends (arg: infer A, ...rest: any) => any ? A : never

type TAuth = typeof auth
const authInstanceFunctions:Array<keyof TAuth> = [
  'signInWithPopup',
  'signInAnonymously',
  'signInWithCustomToken',
  'signInWithEmailAndPassword',
  'signInWithPhoneNumber',
  'signInWithRedirect',
  'signInWithCredential',
  'fetchSignInMethodsForEmail',
  'onAuthStateChanged',
  'signOut',
  'sendPasswordResetEmail',
  'sendEmailVerification',
  'setPersistence',
  'createUserWithEmailAndPassword'
]


type FirebaseAuth = {
  [K in keyof TAuth]: FirstArg<TAuth[K]> extends auth.Auth ? WithoutFirstArg<TAuth[K]> : TAuth[K]
} & ReturnType<typeof auth.getAuth>


class Firebase {
  _app: _firebase.FirebaseApp = null

  constructor() {}

  get app() {
    if(this._app) {
      return this._app
    }

    this._app = _firebase.initializeApp(config)

    return this._app
  }

  get auth() {
    const authInstance = auth.getAuth(this.app)
    const authObj = {
      ...auth,
      ...authInstance
    }
    const authProxy = new Proxy(authObj, {
      get(target, p, receiver) {
        if(authInstanceFunctions.includes(p as any)) {
          return (...args: any) => {
            return target[p](authInstance, ...args)
          }
        }

        return target[p as any]
      },
    })

    return authProxy as unknown as FirebaseAuth
  }
}

export const firebase = new Firebase()
