import { OptimumChatMessage } from '@/types'
import { View, Avatar, Link, Image as ImageCP, Text } from '@/components'
import { createStyles } from '@codeleap/styles'
import { React, AppImages, MAX_APP_WIDTH, theme } from '@/config'
import ReactMarkdown from 'react-markdown'
import { useAnimatedStyle } from '@codeleap/web'
import { onMount, TypeGuards, useEffect, useState } from '@codeleap/common'
import { bubbleDelay } from '@/utils'

const ChatBubble = () => {
  return (
    <View
      style={[
        styles['card:assistant'],
        styles.loadingBubbleWrapper,
        'row',
        'gap:1',
        'center',
      ]}
    >
      <style>{keyframes}</style>
      {Array(3)
        .fill('')
        .map((_, index) => (
          <View
            style={[
              styles.loadingBubbleDot,
              { animationDelay: `${index * 0.16}s` },
            ]}
          />
        ))}
      <ImageCP
        alt="Tail"
        source={AppImages.AssistantTailMessage}
        style={styles['tail:assistant']}
      />
    </View>
  )
}

export type OptimumChatMessageCardProps = OptimumChatMessage & {
  // When the last message is from the same role, the tail is not shown
  isContinuous?: boolean
  talking: boolean
  isInitialMessage: boolean
  hasCompletedOnboarding: boolean
}

export const OptimumChatMessageCard = ({
  isContinuous = false,
  talking,
  isInitialMessage,
  hasCompletedOnboarding,
  isNew = false,
  ...props
}: OptimumChatMessageCardProps) => {
  const [mounted, setMounted] = useState(false)
  const [displayLoadingBubble, setDisplayLoadingBubble] = useState(
    talking || isInitialMessage
  )

  onMount(() => {
    setMounted(true)
  })

  const isAssistant = props?.role === 'assistant'
  const cartStyle = isAssistant ? 'card:assistant' : 'card:user'
  const tailStyle = isAssistant ? 'tail:assistant' : 'tail:user'
  const tailSource =
    AppImages[isAssistant ? 'AssistantTailMessage' : 'UsertTailMessage']

  const isAssistantLastMessage = isAssistant && !isContinuous

  const recipes = props.assistant_data?.recipes || []
  const products = props.assistant_data?.products || []
  const allItems = [...products, ...recipes]

  const assistantAnimation = {
    left: mounted ? 0 : -MAX_APP_WIDTH,
    transition: {
      duration: 0.5,
      ease: 'anticipate',
    },
  }

  const userAnimation = {
    right: mounted ? 0 : -MAX_APP_WIDTH,
    transition: {
      duration: 0.5,
      ease: 'anticipate',
    },
  }

  const animation = useAnimatedStyle(
    () => (isAssistant ? assistantAnimation : userAnimation),
    [mounted, isAssistant]
  )

  const canDisplayLoadingBubble = displayLoadingBubble && isAssistant && isNew

  const [productsImageInfo, setProductsImageInfo] = useState(false)

  useEffect(() => {
    if (isAssistant) {
      let buubleDelay = bubbleDelay(props?.message)

      setTimeout(() => {
        setDisplayLoadingBubble(false)
      }, buubleDelay)
    }
  }, [isAssistant, hasCompletedOnboarding])

  useEffect(() => {
    if (allItems?.length) {
      const updatedMap: Record<string, boolean> = {};
      allItems.forEach(product => {
        if (product?.image_url && TypeGuards.isUndefined(productsImageInfo[product?.image_url])) {
          const img = new Image();
          img.src = product?.image_url;
          img.onload = () => {
            updatedMap[product.image_url] = img.width > img.height;
            setProductsImageInfo(prev => ({ ...prev, ...updatedMap }));
          };
        }
      });
    }
  }, [allItems, productsImageInfo]);

  console.log(allItems, 'all items')

  return (
    <View
      animated
      style={[isContinuous ? 'marginBottom:1' : 'marginBottom:2', 'relative']}
      animatedProps={animation}
    >
      {isAssistantLastMessage ? (
        <Avatar
          debugName="Optimum"
          image={AppImages?.OptimumAvatar}
          style={['optimumChat', 'marginRight:1', 'marginTop:auto']}
        />
      ) : (
        <View
          style={{
            height: theme.values.itemHeight.small,
            width: theme.values.itemHeight.small,
            marginLeft: theme.spacing.value(1),
          }}
        />
      )}

      {canDisplayLoadingBubble ? (
        <ChatBubble />
      ) : (
        <>
          <View style={[styles[cartStyle]]}>
            <ReactMarkdown className="react-markdown">
              {props?.message}
            </ReactMarkdown>

            {!isContinuous && (
              <ImageCP alt="Tail" source={tailSource} style={styles[tailStyle]} />
            )}

            <View
              style={[
                styles.images,
                {
                  gridTemplateColumns:
                    allItems?.length > 1 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
                  marginTop: allItems?.length > 0 ? theme.spacing.value(2) : 0,
                },
              ]}
            >
              {allItems?.map(product => {
                if (!product?.image_url) return null
                return (
                  <Link
                    to={product?.link || ''}
                    openNewTab
                    style={'hoverEffect'}
                  >
                    <ImageCP
                      alt={product?.name}
                      source={product?.image_url as any}
                      style={styles.image}
                      objectFit={productsImageInfo[product?.image_url] ? 'cover' : 'contain'}
                    />
                  </Link>
                )
              })}
            </View>
          </View>
        </>
      )}
    </View>
  )
}

const keyframes = `
  @keyframes dotBounce {
    0%, 80%, 100% {
      transform: scale(0.5);
      opacity: 0.3;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
`

const styles = createStyles(theme => ({
  'card:assistant': {
    ...theme.presets.column,
    ...theme.presets.relative,
    backgroundColor: theme.colors.primary1,
    ...theme.spacing.padding(1.5),
    borderRadius: theme.borderRadius.regular,
    maxWidth: '70%',
  },
  'card:user': {
    ...theme.presets.relative,
    backgroundColor: theme.colors.yellow1,
    ...theme.spacing.padding(1.5),
    borderRadius: theme.borderRadius.regular,
    maxWidth: '70%',
    marginLeft: 'auto',
  },
  'tail:assistant': {
    bottom: 0,
    left: -6,
    height: 21,
    width: 21,
    zIndex: -1,
    ...theme.presets.absolute,
  },
  'tail:user': {
    bottom: 0,
    right: -6,
    height: 21,
    width: 21,
    zIndex: -1,
    ...theme.presets.absolute,
  },
  images: {
    display: 'grid',
    gap: theme.spacing.value(1),
  },
  image: {
    borderRadius: theme.borderRadius.small,
    border: `2px solid ${theme.colors.neutral3}`,
    height: 130,
  },
  loadingBubbleWrapper: {
    height: theme.values.itemHeight.default,
    width: 80,
  },
  loadingBubbleDot: {
    width: 6,
    height: 6,
    borderRadius: theme.borderRadius.rounded,
    backgroundColor: theme.colors.neutral6,
    animation: 'dotBounce 1.4s infinite ease-in-out',
  },
}))
