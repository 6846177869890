import { Button, Image, View } from '@/components'
import { AppImages } from '@/config'

export const FakeHeader = ({ showAddMeal = false }) => {
  return (
    <View
      style={[
        'backgroundColor:neutral10',
        'padding:3',
        'alignCenter',
        'justifySpaceBetween',
        'paddingHorizontal:2',
      ]}
    >
      <Image source={AppImages.HeaderLogo} style={{ width: 78, height: 32 }} />
      {showAddMeal ? (
        <Button
          text="Add meals"
          style={['small', 'paddingLeft:0']}
          icon={'plus'}
        />
      ) : null}
    </View>
  )
}
