import React from 'react'
import { AppImages, Settings, StyleSheets } from '@/config'
import { ActivityIndicatorCP } from './ActivityIndicator'
import * as LibComponents from '@codeleap/web'

import { Image } from './Image'
LibComponents.Modal.defaultProps.scrollLock = true
LibComponents.Modal.defaultProps.autoIndex = true
LibComponents.Select.defaultProps.selectedIcon = 'check'
LibComponents.Select.defaultProps.clearable = true
LibComponents.Select.defaultProps.noItemsImage =
  AppImages.EmptyBox as unknown as string
LibComponents.Select.defaultProps.components = { ClearIndicator: null }
LibComponents.TextInput.defaultProps.visibleIcon = 'eye'
LibComponents.TextInput.defaultProps.hiddenIcon = 'eye-off'
LibComponents.SearchInput.defaultProps.searchIcon = 'search'
LibComponents.SearchInput.defaultProps.clearIcon = 'x'
LibComponents.Modal.defaultProps.closeIconName = 'x'
LibComponents.ActivityIndicator.defaultProps.component =
  ActivityIndicatorCP as () => JSX.Element
LibComponents.List.defaultProps.refresh = false
LibComponents.List.defaultProps.overscan = 7
LibComponents.Grid.defaultProps.refresh = false
LibComponents.Grid.defaultProps.overscan = 7
LibComponents.EmptyPlaceholder.defaultProps.ImageComponent = Image

LibComponents.CropPicker.defaultProps.targetCrop = {
  aspect: Settings.Images.Aspect.default,
  ruleOfThirds: true,
}

// Library Components

export const ActionIcon = LibComponents.ActionIcon.withVariantTypes(
  StyleSheets.ActionIconStyles
)
export const ActivityIndicator =
  LibComponents.ActivityIndicator.withVariantTypes(
    StyleSheets.ActivityIndicatorStyles
  )
export const Badge = LibComponents.Badge.withVariantTypes(
  StyleSheets.BadgeStyles
)
export const Button = LibComponents.Button.withVariantTypes(
  StyleSheets.ButtonStyles
)
export const Checkbox = LibComponents.Checkbox.withVariantTypes(
  StyleSheets.CheckboxStyles
)
export const Collapse = LibComponents.Collapse.withVariantTypes(
  StyleSheets.CollapseStyles
)
export const CropPicker = LibComponents.CropPicker.withVariantTypes(
  StyleSheets.CropPickerStyles
)
export const ColorPicker = LibComponents.ColorPicker.withVariantTypes(
  StyleSheets.ColorPickerStyles
)
export const DatePicker = LibComponents.DatePicker.withVariantTypes(
  StyleSheets.DatePickerStyles
)
export const Drawer = LibComponents.Drawer.withVariantTypes(
  StyleSheets.DrawerStyles
)
export const Dropzone = LibComponents.Dropzone.withVariantTypes(
  StyleSheets.DropzoneStyles
)
export const EmptyPlaceholder = LibComponents.EmptyPlaceholder.withVariantTypes(
  StyleSheets.EmptyPlaceholderStyles
)
export const Grid = LibComponents.Grid.withVariantTypes(StyleSheets.GridStyles)
export const Icon = LibComponents.Icon.withVariantTypes(StyleSheets.IconStyles)
export const List = LibComponents.List.withVariantTypes(StyleSheets.ListStyles)
export const LoadingOverlay = LibComponents.LoadingOverlay.withVariantTypes(
  StyleSheets.LoadingOverlayStyles
)
export const Modal = LibComponents.Modal.withVariantTypes(
  StyleSheets.ModalStyles
)
export const NumberIncrement = LibComponents.NumberIncrement.withVariantTypes(
  StyleSheets.NumberIncrementStyles
)
export const Overlay = LibComponents.Overlay.withVariantTypes(
  StyleSheets.OverlayStyles
)
export const Pager = LibComponents.Pager.withVariantTypes(
  StyleSheets.PagerStyles
)
export const PaginationIndicator =
  LibComponents.PaginationIndicator.withVariantTypes(
    StyleSheets.PaginationIndicatorStyles
  )
export const ProgressBar = LibComponents.ProgressBar.withVariantTypes(
  StyleSheets.ProgressBarStyles
)
export const ProgressCircle = LibComponents.ProgressCircle.withVariantTypes(
  StyleSheets.ProgressCircleStyles
)
export const RadioInput = LibComponents.RadioInput.withVariantTypes(
  StyleSheets.RadioInputStyles
)
export const SectionFilters = LibComponents.SectionFilters.withVariantTypes(
  StyleSheets.SectionFiltersStyles
)
export const SegmentedControl = LibComponents.SegmentedControl.withVariantTypes(
  StyleSheets.SegmentedControlStyles
)
export const Slider = LibComponents.Slider.withVariantTypes(
  StyleSheets.SliderStyles
)
export const Switch = LibComponents.Switch.withVariantTypes(
  StyleSheets.SwitchStyles
)
export const Select = LibComponents.Select.withVariantTypes(
  StyleSheets.SelectStyles
)
export const Tag = LibComponents.Tag.withVariantTypes(StyleSheets.TagStyles)
export const Text = LibComponents.Text.withVariantTypes(StyleSheets.TextStyles)
export const TextEditor = LibComponents.TextEditor.withVariantTypes(
  StyleSheets.TextEditorStyles
)
export const TextInput = LibComponents.TextInput.withVariantTypes(
  StyleSheets.TextInputStyles
)
export const Tooltip = LibComponents.Tooltip.withVariantTypes(
  StyleSheets.TooltipStyles
)
export const Touchable = LibComponents.Touchable.withVariantTypes(
  StyleSheets.TouchableStyles
)
export const View = LibComponents.View.withVariantTypes(StyleSheets.ViewStyles)
export const PaginationButtons =
  LibComponents.PaginationButtons.withVariantTypes(
    StyleSheets.PaginationButtonStyles
  )

export const { FileInput, SearchInput, InputBase } = LibComponents

// App Components

export * from './Auth'
export * from './Avatar'
export * from './ActivityIndicator'
export * from './Animations'
export * from './AppStatus'
export * from './Cards'
export * from './CenterWrapper'
export * from './Footer'
export * from './GlobalStyle'
export * from './Header'

export * from './LanguageSelector'
export * from './Link'
export * from './LoadingScreen'
export * from './Logo'
export * from './Modals'
export * from './Alert'
export * from './Navigation'
export * from './Onboarding'
export * from './Page'
export * from './PhoneInput'
export * from './Toolbar'
export * from './AppWrapper'
export * from './Examples'
export * from './Image'
export * from './Optimum'
export * from './fake'
