import { AppImages, theme } from '@/config'
import { createStyles } from '@codeleap/styles'
import {
  Image,
  Text,
  View,
  Button,
  Modal,
  Pager,
  Touchable,
} from '@/components'
import { PropsOf, useState } from '@codeleap/common'

export type OptimumChatOnboardingProps = PropsOf<typeof Modal>

const data = [
  {
    title: 'Personalised Workouts',
    description:
      'Get a workout plan with exercises aligned to your fitness level and goals.',
    button: 'Next',
    image: AppImages.OptimumOnboarding1,
  },
  {
    title: 'Protein Calculator',
    description: `Discover your daily protein needs with Coach Optimum's calculator.`,
    button: 'Next',
    image: AppImages.OptimumOnboarding2,
  },
  {
    title: 'Explore the Demo',
    description: `Chat now with Coach Optimum for a preview of how these fitness & nutritional tools will work.`,
    button: 'Get started',
    image: AppImages.OptimumOnboarding3,
  },
]

const Item = ({
  title,
  description,
  button,
  image,
  page,
  onNextPage,
  onChangePage,
}) => (
  <View style={[styles.wrapper]}>
    <View style={styles.content}>
      <View style={styles.poweredBy}>
        <Text
          text="Powered by AI technology"
          style={['p4', 'color:neutral3', 'regular']}
        />
      </View>

      <View style={styles.imageWrapper}>
        <Image
          alt="Chat onboarding"
          source={AppImages.Format}
          style={styles.imageFormat}
        />
        <Image alt="Chat onboarding" source={image} style={styles.image} />
      </View>

      <Text
        text={title}
        style={[
          'h2',
          'color:neutral1',
          'semiBold',
          'textCenter',
          'marginBottom:1',
          {
            breakpoints: {
              tiny: {
                fontSize: 20,
              },
            },
          },
        ]}
      />

      <Text
        text={description}
        style={[
          'h5',
          'color:neutral3',
          'textCenter',
          'regular',
          { maxWidth: '75%', breakpoints: { tiny: { fontSize: 12 } } },
        ]}
      />
    </View>
  </View>
)

export const OptimumChatOnboarding = ({
  toggle,
  ...rest
}: OptimumChatOnboardingProps) => {
  const [page, setPage] = useState(0)

  const onNextPage = () => {
    if (page === data.length - 1) {
      return toggle()
    }
    setPage(state => state + 1)
  }

  const onChangePage = page => setPage(page)

  const isLastPage = page === data.length - 1

  const buttonText = isLastPage ? 'Get started' : 'Next'

  return (
    <Modal toggle={toggle} style={['fullscreen']} showClose={false} {...rest}>
      <Image
        alt="Chat onboarding"
        objectFit={'cover'}
        source={AppImages.ChatOnboarding}
        style={styles.background}
      />

      <View style={styles.header}>
        <Image
          alt="Chat onboarding"
          source={AppImages.HeaderLogo}
          style={styles.logo}
        />
      </View>
      <Pager
        page={page}
        dots={true}
        onChange={onChangePage}
        style={['optimumOnboarding']}
        footer={
          <View style={['center']}>
            <Button
              text={buttonText}
              debugName="Chat Onboarding Get Started"
              style={['borderRadius:rounded', 'large', { width: 150 }]}
              onPress={onNextPage}
            />
          </View>
        }
      >
        {data.map(item => (
          <Item
            key={item.title}
            {...item}
            page={page}
            onNextPage={onNextPage}
            onChangePage={onChangePage}
          />
        ))}
      </Pager>
    </Modal>
  )
}

const HEADER_HEGIHT = 72

const styles = createStyles(theme => ({
  wrapper: {
    flex: 1,
    ...theme.presets.fullWidth,
    ...theme.presets.relative,
    ...theme.presets.column,
    ...theme.presets.alignCenter,
    ...theme.presets.justifyCenter,
    height: '80svh',
    paddingTop: HEADER_HEGIHT,
  },
  content: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.presets.alignCenter,
    ...theme.spacing.paddingHorizontal(4),
  },
  background: {
    flex: 1,
    ...theme.presets.absolute,
    height: '100%',
    width: '100%',
    zIndex: -1,
  },
  header: {
    position: 'absolute',
    top: 0,
    height: HEADER_HEGIHT,
    ...theme.presets.fullWidth,
    ...theme.spacing.paddingHorizontal(2),
  },
  logo: {
    width: HEADER_HEGIHT,
  },
  poweredBy: {
    ...theme.spacing.paddingHorizontal(1),
    ...theme.spacing.paddingVertical(0.5),
    marginBottom: '1svh',
    borderRadius: theme.borderRadius.small,
    backgroundColor: `rgba(230, 142, 156, 0.30)`,
    ...theme.effects.light,
  },
  imageWrapper: {
    ...theme.presets.relative,
    ...theme.presets.fullWidth,
    height: `35svh`,
    ...theme.spacing.marginVertical(4),
  },
  imageFormat: {
    ...theme.presets.fullWidth,
    height: '85%',
    ...theme.presets.absolute,
    top: 0,
  },
  image: {
    ...theme.presets.fullWidth,
    ...theme.presets.fullHeight,
  },
}))
