import React, { useRef, useState } from 'react'
import { Image, View, OptimumChatOnboarding } from '@/components'
import Head from 'next/head'
import { v4 as uuidv4 } from 'uuid'
import { useCookies } from '@/utils'
import { APIClient } from '@/services'
import { createStyles } from '@codeleap/styles'
import { AppImages, COOKIES_KEYS } from '@/config'

import {
  OptimumChatMessages,
  OptimumChatFooter as Footer,
  OptimumChatHeader as Header,
} from './components'
import { OPTIMUM_CHAT_CONSTANTS } from './constants'
import dynamic from 'next/dynamic'
const { HEADER_HEIGHT, FOOTER_HEIGHT, PAGE_TITLE, INITIAL_MESSAGE } =
  OPTIMUM_CHAT_CONSTANTS

const manager = APIClient.Optimum.Chat.messagesManager

const dateIsoString = new Date().toISOString()
const randomId = uuidv4()
const cookieKey = `${randomId}:${dateIsoString}`

export const Messages = dynamic(() => Promise.resolve(OptimumChatMessages), {
  ssr: false,
}) as typeof OptimumChatMessages

const OptimumChat = () => {
  const [showOnboardingModal, setShowOnboardingModal] = useCookies({
    key: COOKIES_KEYS.OPTIMUM_CHAT_ONBOARDING,
    defaultValue: 'true',
  })

  const [thread_ref, setThreadRef] = useCookies({
    key: COOKIES_KEYS.OPTIMUM_CHAT,
    defaultValue: cookieKey,
  })

  const messagesQuery = manager.use({
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled: !!thread_ref,
      },
    },
    filter: { thread_ref },
  })

  const [talking, setTalking] = useState(false)
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)

  const handleSendMessage = (message: string) => {
    setLoading(true)
    scrollRef.current?.scrollTo(0, 0)
    const body = { message, thread_ref }
    manager.actions
      .askOptimum({
        body,
        onSuccess: () => {
          setLoading(false)
          setTalking(true)
        },
      })
      .finally(() => {
        setLoading(false)
        setTalking(false)
      })
  }

  const handleSuggestionPress = (suggestion: string) => {
    handleSendMessage(suggestion)
  }

  const onReset = () => {
    const newRandomId = uuidv4()
    const newCookieKey = `${newRandomId}:${new Date().toISOString()}`
    setThreadRef(newCookieKey)
  }

  return (
    <>
      <Head>
        <title>{PAGE_TITLE}</title>
      </Head>

      <View style={styles.wrapper}>
        <Header
          title={PAGE_TITLE}
          onReset={onReset}
          hasMessages={!!messagesQuery?.list?.items?.length}
        />
        <Messages
          loading={loading}
          talking={talking}
          scrollRef={scrollRef}
          messagesQuery={messagesQuery}
          optimumInitialMessage={INITIAL_MESSAGE}
          onSuggestionPress={handleSuggestionPress}
          hasCompletedOnboarding={showOnboardingModal === 'false'}
        />
        <Footer
          onSendMessage={handleSendMessage}
          disabled={loading || talking}
        />
        <Image
          style={styles.background}
          alt="Optimum Chat Background"
          source={AppImages.OptimumChatBg}
          objectFit="cover"
        />
      </View>

      <OptimumChatOnboarding
        visible={showOnboardingModal === 'true'}
        toggle={() => setShowOnboardingModal('false')}
      />
    </>
  )
}

const styles = createStyles(theme => ({
  wrapper: {
    flex: 1,
    height: '100svh',
    ...theme.presets.column,
  },
  background: {
    height: `calc(100svh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
    top: HEADER_HEIGHT,
    left: 0,
    zIndex: -2,
    ...theme.presets.fixed,
    ...theme.presets.fullWidth,
  },
}))

export default OptimumChat
