export const light = {
  primary1: '#FDEFF1',
  primary2: '#FBC7CF',
  primary3: '#F46C82',
  primary4: '#F45B74',
  primary5: '#F03F5C',
  primary6: '#ED1538',
  primary7: '#C91230',
  primary8: '#A80F28',
  primary9: '#870C20',
  primary10: '#6B0919',
  secondary1: '#E4E4F8',
  secondary2: '#B3B4F8',
  secondary3: '#8183F8',
  secondary4: '#6668C4',
  secondary5: '#4C4D91',
  neutral1: '#FFFFFF',
  neutral2: '#F2F2F2',
  neutral3: '#E5E5E5',
  neutral4: '#D9D9D9',
  neutral5: '#CCCCCC',
  neutral6: '#B3B3B3',
  neutral7: '#999999',
  neutral8: '#666666',
  neutral9: '#333333',
  neutral10: '#000000',
  positive1: '#4CB7D5',
  positive2: '#F5F0D1',
  warning1: '#F3DBDB',
  warning2: '#4CB7D5',
  alert1: '#E4C000',
  alert2: '#F33F3F',
  destructive1: '#F3DBDB',
  destructive2: '#F33F3F',
  background: '#FFFFFF',
  card: '#4CB7D5',
  separator: '#E5E5E5',
  border: '#CCCCCC',
  overlay: '#000000',
  headlines: '#000',
  body: '#666666',
  caption: '#999999',
  ripple: '#0002',
  transparent: '#FFF0',
  header: '#000',
  splash: '#000',
  yellow1: '#FFFBEB',
  red: '#D84C4C',
  red1: '#FAE9E9',
  red2: '#F4CBCB',
  red3: '#EBA1A1',
  red4: '#E17676',
  red5: '#D84C4C',
  red6: '#D02525',
  red7: '#B11F1F',
  red8: '#941A1A',
  red9: '#771515',
  red10: '#5E1111'
}

export const dark = {
  primary1: '#FDEFF1',
  primary2: '#FBC7CF',
  primary3: '#F46C82',
  primary4: '#F45B74',
  primary5: '#F03F5C',
  primary6: '#ED1538',
  primary7: '#C91230',
  primary8: '#A80F28',
  primary9: '#870C20',
  primary10: '#6B0919',
  secondary1: '#2F2F59',
  secondary2: '#434480',
  secondary3: '#8B8DF8',
  secondary4: '#7475B2',
  secondary5: '#A3A4CC',
  neutral1: '#1A1A1A',
  neutral2: '#333333',
  neutral3: '#4D4D4D',
  neutral4: '#D9D9D9',
  neutral5: '#737373',
  neutral6: '#B3B3B3',
  neutral7: '#999999',
  neutral8: '#CCCCCC',
  neutral9: '#333333',
  neutral10: '#FFFFFF',
  positive1: '#3D6652',
  positive2: '#42C586',
  warning1: '#66603D',
  warning2: '#E4C000',
  alert1: '#663D3D',
  alert2: '#FF4E4E',
  destructive1: '#663D3D',
  destructive2: '#FF4E4E',
  background: '#1A1A1A',
  card: '#333333',
  separator: '#4D4D4D',
  border: '#737373',
  overlay: '#000000',
  headlines: '#FFFFFF',
  body: '#B3B3B3',
  caption: '#666666',
  ripple: '#0002',
  transparent: '#FFF0',
  header: '#000',
  splash: '#000',
  yellow1: '#FFFBEB',
  red: '#D84C4C',
  red1: '#FAE9E9',
  red2: '#F4CBCB',
  red3: '#EBA1A1',
  red4: '#E17676',
  red5: '#D84C4C',
  red6: '#D02525',
  red7: '#B11F1F',
  red8: '#941A1A',
  red9: '#771515',
  red10: '#5E1111'
}
