import React from 'react'
import { createStyles } from '@codeleap/styles'
import { useBooleanToggle } from '@codeleap/common'
import { Image, Text, View, ActionIcon, Avatar } from '@/components'
import { AppImages, MAX_APP_WIDTH, navigation, OSAlert } from '@/config'
import { OPTIMUM_CHAT_CONSTANTS } from '../constants'

export type OptimumChatHeaderProps = {
  title: string
  hasMessages: boolean
  onReset?: () => void
}

export const OptimumChatHeader = ({
  hasMessages,
  title,
  onReset,
}: OptimumChatHeaderProps) => {
  const [visible, toggle] = useBooleanToggle(false)

  const onConfirmErase = () => {
    if (!hasMessages) {
      return OSAlert.info({
        title: 'No messages to be erased',
        onDismiss: toggle,
      })
    }

    OSAlert.ask({
      title: 'Erase conversation',
      body: 'Are you sure you want to erase this conversation? This action cannot be undone.',
      options: [
        {
          text: 'Cancel',
          onPress: toggle,
        },
        {
          text: 'Erase',
          style: 'destructive',
          onPress: () => {
            onReset()
            toggle()
          },
        },
      ],
    })
  }

  return (
    <View style={styles.header}>
      <View>
        <ActionIcon
          debugName="Header Back"
          name="chevron-left"
          style={['iconSize:3']}
          onPress={() => {
            navigation.navigate('Exercise')
          }}
        />
        <View style={['alignCenter', 'marginLeft:2']}>
          <Avatar
            debugName="Optimum"
            image={AppImages?.OptimumAvatar}
            style={['optimumChat']}
          />
          <Text
            text={title}
            style={[
              'marginLeft:1',
              'h3',
              'color:neutral1',
              {
                position: 'relative',
                top: 2,
              },
            ]}
          />
        </View>
      </View>

      <ActionIcon
        debugName="Erase conversation"
        icon="refresh"
        style={['iconSize:3', 'neutral5']}
        onPress={onConfirmErase}
      />
    </View>
  )
}

const { HEADER_HEIGHT, FOOTER_HEIGHT } = OPTIMUM_CHAT_CONSTANTS

const styles = createStyles(theme => ({
  wrapper: {
    flex: 1,
    height: '100svh',
    ...theme.presets.column,
  },
  header: {
    ...theme.presets.fullWidth,
    ...theme.presets.alignCenter,
    ...theme.presets.justifySpaceBetween,
    backgroundColor: theme.colors.neutral10,
    height: HEADER_HEIGHT,
    ...theme.spacing.paddingHorizontal(2),
  },
  scroll: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column-reverse',
    ...theme.presets.relative,
    padding: theme.spacing.value(2),
  },
  inputWrapper: {
    display: 'flex',
    marginTop: 'auto',
    ...theme.presets.fullWidth,
    backgroundColor: theme.colors.neutral2,
    ...theme.presets.alignCenter,
    ...theme.spacing.paddingTop(1),
    ...theme.spacing.paddingHorizontal(2),
    ...theme.spacing.paddingBottom(2),
    borderTop: `1px solid ${theme.colors.neutral3}`,
  },
  background: {
    height: `calc(100svh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
    top: HEADER_HEIGHT,
    left: 0,
    zIndex: -2,
    ...theme.presets.fixed,
    ...theme.presets.fullWidth,
  },
  confettiAnimationWrapper: {
    position: 'fixed',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: MAX_APP_WIDTH,
    height: '90svh',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
}))
