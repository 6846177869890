function opacify(hex: string, opacity: number) {
  if (hex.charAt(0) === '#') {
    hex = hex.slice(1)
  }

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('')
  }

  const alpha = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0')

  return `#${hex}${alpha}`
}

export const ColorUtils = {
  opacify,
}
