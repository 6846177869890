import { onMount, useState } from '@codeleap/common'
import Cookies from 'js-cookie'

type UseCookiesProps = {
  key: string,
  defaultValue?: string
}

export const useCookies = (props: UseCookiesProps) => {

  const {
    key,
    defaultValue,
  } = props

  const [cookiesValue, _setCookiesValue] = useState<string | undefined>(undefined)

  onMount(() => {
    if (!defaultValue) return
    const cookiesValue = Cookies.get(key)
    if (!cookiesValue) {
      Cookies.set(key, defaultValue)
      _setCookiesValue(defaultValue)
    } else {
      _setCookiesValue(cookiesValue)
    }
  })

  const setCookieValue = (newValue: string) => {
    Cookies.set(key, newValue)
    _setCookiesValue(newValue)
  }

  return [cookiesValue, setCookieValue] as const
}
