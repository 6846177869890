import { createSettings } from '@codeleap/common'
import Obfuscate from './Obfuscate'

import { IS_SSR } from './constants'

export const IsDevEnv = process.env.NODE_ENV === 'development'

const ENV = IsDevEnv ? 'development' : 'production'

let _Settings = createSettings({
  AppName: 'Optimum Nutrition',
  CompanyName: 'Optimum Nutrition',
  Description: 'Nutrition app',
  AppConfig: {
    emailVerificationEnabled: true,
    signupCompleteVerificationEnabled: true,
  },
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Images: {
    Aspect: {
      default: 1,
    },
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Website: {
    ProductionURL: 'http://template.codeleap.co.uk/',
    DevelopmentURL: 'http://template.codeleap.co.uk/',
  },
  Fetch: {
    ProductionURL: 'https://prod.optimumnutrition.codeleap.co.uk/',
    DevelopmentURL: 'https://prod.optimumnutrition.codeleap.co.uk/',
    ViewHTMLErrors: true,
  },
  Social: {
    FacebookURL: 'https://www.facebook.com/codeleapuk/',
    LinkedinURL: 'https://www.linkedin.com/company/codeleap-uk',
  },
  ContactINFO: {
    Website: 'codeleap.co.uk',
    TermsAndPrivacy: 'https://codeleap.co.uk',
    SupportEMAIL: 'support@codeleap.co.uk',
    ContactEMAIL: 'hello@codeleap.co.uk',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    DeviceIdentifier: IS_SSR ? '' : window?.navigator?.userAgent,
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      'new NativeEventEmitter',
      'User cancelled',
      'React does not recognize the',
      'Unknown event handler property',
      'forwardRef render functions accept exactly ',
    ],
    Obfuscate,
    ComponentLogging: true,
  },
  // Sentry: {
  //   enable: false, // not change this

  //   dsn: "",
  //   tracesSampleRate: IsDevEnv ? 1.0 : 0.4,
  //   environment: ENV,
  //   debug: IsDevEnv,
  //   enabled: !IsDevEnv,
  //   release: '1.0',
  // },
  // Slack: {
  //   echo: {
  //     icon: 'https://avatars.githubusercontent.com/u/48894125?s=200&v=4',
  //     token: '',
  //     channel: '#_dev_logs',
  //   },
  // },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId: '',
    },
    AppleSignIn: {
      ServiceClientId: '',
      RedirectURI: '',
    },
    FacebookSDK: {
      AppId: '',
    },
  },
})

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndPrivacy) {
  _Settings.ContactINFO.TermsAndPrivacy = `https://codeleap.co.uk/dev/policies/?app=${Buffer.from(
    _Settings.AppName,
    'base64'
  )}`
}

export const Settings = _Settings
