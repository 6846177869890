import { OptimumChatMessageCardProps } from '../OptimumChatMessageCard'

const optimumInitialMessage: Partial<OptimumChatMessageCardProps> = {
  role: 'assistant',
  message:
    "Hi there! 👋\n\nI'm Coach Optimum, combining science and experience to help you reach your health and fitness goals.\nFrom Harvard to Hong Kong, I've guided people worldwide in their fitness journeys, and I'm excited to help you too!\n\nWhat brings you here today? 💪",
  assistant_data: {
    suggestions: ['Lose weight 🏋️‍♂️', 'Build muscle 💪', 'Eat healthier 🥗'],
    mood: 'smiley',
  },
  isNew: true,
}

export const OPTIMUM_CHAT_CONSTANTS = {
  PAGE_TITLE: 'Coach Optimum',
  HEADER_HEIGHT: 72,
  FOOTER_HEIGHT: 60,
  INITIAL_MESSAGE: optimumInitialMessage,
}
