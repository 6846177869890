import { AppStatus, useAppSelector } from '@/redux'
import {
  onUpdate,
  ReactQuery,
  TypeGuards,
  useIsomorphicEffect,
  waitFor,
} from '@codeleap/common'
import { create, retrieve, update } from './views'
import { QueryKeys } from './queryKeys'
import { useToggleDevMode } from '@/utils'
import { useState } from 'react'
import { queryClient } from '../queryClient'
import { IsDevEnv, LocalStorage, navigation, Settings } from '@/config'
import { OnboardingStore } from '@/components'
import { APIClient } from '@/services'
import { Profile } from '@/types'
import { firebase } from '@/services/firebase'

const DEBUG = IsDevEnv && false

const verify = v => !TypeGuards.isNil(v)

export const userHasSocialLogin = async () => {
  if (IsDevEnv && DEBUG) return true

  const firebaseUser = firebase.auth.currentUser

  const signInMethods = await firebase.auth.fetchSignInMethodsForEmail(
    firebaseUser?.email
  )

  const idTokenResult = await firebaseUser.getIdTokenResult()

  if (idTokenResult?.claims?.linkedin_id) {
    return true
  }

  const hasSocialLogin = signInMethods?.some(x => x !== 'password')

  return hasSocialLogin
}

type CheckSignupCompletionResult = {
  isComplete: boolean
  nextStep: AppRoute | null
}

export async function checkSignupCompletion(
  data: Profile
): Promise<CheckSignupCompletionResult> {
  const firebaseUser = firebase.auth.currentUser

  const hasSocialLogin = await userHasSocialLogin()

  const emailNotVerified =
    !firebaseUser?.emailVerified && data?.id === firebaseUser?.uid

  if (
    !!firebaseUser &&
    emailNotVerified &&
    Settings.AppConfig.emailVerificationEnabled
  ) {
    if (!hasSocialLogin && !navigation.isCurrentRoute('Auth.VerifyEmail')) {
      return {
        isComplete: false,
        nextStep: 'Auth.VerifyEmail',
      }
    }
  }

  if (!Settings.AppConfig.signupCompleteVerificationEnabled) {
    return {
      isComplete: true,
      nextStep: null,
    }
  }

  const signupInfo = [data.first_name, data.last_name, data.email]

  const hasCompletedSignup = signupInfo.every(verify)

  const steps = [['Signup', hasCompletedSignup]] as const

  const nextStep = steps.find(x => !x[1])?.[0]
  const isComplete = steps.every(x => x[1])

  return {
    isComplete,
    nextStep: `Auth.${nextStep}`,
  }
}

export function useSession(root = false) {
  const devMode = useToggleDevMode(root)

  const authFinished = useAppSelector(store => store.AppStatus.authFinished)
  const initialAuthResolved = useAppSelector(
    store => store.AppStatus.hasResolvedInitialAuth
  )

  const [hasFirebaseUser, setHasFirebaseUser] = useState(null)

  const profile = ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: retrieve,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
    retry: false,
    enabled: false,
    onError() {
      QueryKeys.me.setData(null)
    },
  })

  const createProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: create,
    onSuccess: () => {
      QueryKeys.me.refresh()
    },
  })

  const updateProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: update,
    onSuccess: () => {
      QueryKeys.me.refresh()
    },
  })

  const logout = (withDone = true) => {
    return new Promise<void>(async resolve => {
      if (withDone) {
        AppStatus.set('loading')
      }

      LocalStorage.clear()

      if (withDone) {
        AppStatus.set('loading')
      }

      setTimeout(() => {
        firebase.auth.signOut()
        queryClient.client.clear()
        AppStatus.clearModals()

        setTimeout(() => {
          if (withDone) {
            AppStatus.set('done')
          }

          setTimeout(() => {
            navigation.navigate('Home')
            resolve()
          })
        }, 1000)
      }, 2000)
    })
  }

  const loginResolved = typeof profile.data !== 'undefined'

  const toggleRememberInformation = async (rememberMe: boolean) => {
    firebase.auth.setPersistence(
      rememberMe ? { type: 'SESSION' } : { type: 'NONE' }
    )
  }

  const reauthenticate = async (password: string) => {
    return firebase.auth.signInWithEmailAndPassword(
      profile?.data?.email,
      password
    )
  }

  useIsomorphicEffect(() => {
    if (!root) return

    let unsubscribe: () => void

    const init = async () => {
      unsubscribe = firebase.auth.onAuthStateChanged(async user => {
        if (user?.uid) {
          LocalStorage.setItem('PERSIST_AUTH', 'true')
        } else {
          LocalStorage.removeItem('PERSIST_AUTH')
        }

        if (user) {
          setHasFirebaseUser(user?.uid)
          QueryKeys.me.refresh()

          await waitFor(500)

          let profileData = QueryKeys.me.getData()

          if (!profileData) {
            const profileResult = await profile.refetch()
            profileData = profileResult?.data
            QueryKeys.me.setData(profileData)
          }

          checkProfile(profileData)
        } else {
          QueryKeys.me.setData(null)
        }

        await waitFor(1000)

        AppStatus.set('idle')
      })
    }

    init()
  }, [])

  const checkProfile = async (profile: Profile) => {
    if (TypeGuards.isNil(profile)) return null

    const { isComplete, nextStep } =
      await APIClient.Session.checkSignupCompletion(profile)

    if (!isComplete) {
      navigation.navigate(nextStep)
    }
  }

  onUpdate(() => {
    if (loginResolved && root && !initialAuthResolved) {
      AppStatus.initialAuthResolved()
      OnboardingStore.getState().check()
    }
  }, [loginResolved])

  return {
    profile: profile.data,
    reauthenticate,
    updateProfile,
    createProfile,
    logout,
    isLoggedIn: !!profile.data,
    loginResolved,
    authFinished,
    hasFirebaseUser,
    profileQuery: profile,
    toggleRememberInformation,
    checkProfile,
  }
}

export const useIsSocialLogin = () => {
  // TODO LinkedIn Auth
  // const loggedInWithLinkedin = useAppSelector(store => !!store.Session?.firebaseUserClaims?.linkedin_id)

  const firebaseUser = firebase.auth.currentUser

  const isSocialLogin = !!firebaseUser?.providerData?.some(
    provider => provider?.providerId !== 'password'
  )

  return {
    isSocialLogin,
    firebaseUser,
  }
}
