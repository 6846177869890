import React, { useState } from 'react'
import { bubbleDelay } from '@/utils'
import { onUpdate } from '@codeleap/common'
import { useAnimatedStyle } from '@codeleap/web'
import { OptimumChatAssistantData } from '@/types'
import { View, Button, OptimumChatMessageCardProps } from '@/components'

type OptimumChatSuggestionsProps = {
  optimumInitialMessage?: OptimumChatMessageCardProps
  suggestions: OptimumChatAssistantData['suggestions']
  onSuggestionPress?: (suggestion: string) => void
  hasCompletedOnboarding: boolean
}

export const OptimumChatSuggestions = ({
  optimumInitialMessage,
  suggestions,
  onSuggestionPress,
  hasCompletedOnboarding,
}: OptimumChatSuggestionsProps) => {
  const [displaySuggestions, setDisplaySuggestions] = useState(false)

  onUpdate(() => {
    if (hasCompletedOnboarding) {
      setTimeout(() => {
        setDisplaySuggestions(true)
      }, bubbleDelay(optimumInitialMessage?.message))
    }
  }, [hasCompletedOnboarding])

  const userAnimation = {
    right: displaySuggestions ? 0 : -1000,
    transition: {
      duration: 0.5,
      ease: 'anticipate',
    },
  }

  const animation = useAnimatedStyle(() => userAnimation, [displaySuggestions])

  if (!displaySuggestions) return null

  return (
    <View
      animated
      style={['relative', 'marginBottom:1', 'column']}
      animatedProps={animation}
    >
      {suggestions?.map(suggestion => (
        <Button
          debugName="OptimumChatSuggestion"
          key={suggestion}
          text={suggestion}
          style={['optimumChatSuggestion', 'marginBottom:1']}
          onPress={() => {
            onSuggestionPress(suggestion)
          }}
        />
      ))}
    </View>
  )
}
