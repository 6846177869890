const BUBBLE_MIN_DELAY = 1500
const BUBBLE_MAX_DELAY = 4000

export const bubbleDelay = (message: string): number => {
  const MAX_LENGTH = 280
  const normalizedLength = Math.min(message.length, MAX_LENGTH) / MAX_LENGTH

  const factor = normalizedLength * normalizedLength
  const duration =
    BUBBLE_MIN_DELAY + factor * (BUBBLE_MAX_DELAY - BUBBLE_MIN_DELAY)

  return Math.round(duration)
}
