import { PagerComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

const createPagerVariant = createStyles<PagerComposition>

const DOT_SIZE = 8

export const PagerStyles = {
  default: createPagerVariant(theme => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
    },
    footerWrapper: {
      ...theme.presets.column,
    },
    dots: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      gap: theme.spacing.value(1),
      ...theme.presets.justifyCenter,
    },
    dot: {
      height: DOT_SIZE,
      width: DOT_SIZE,
      cursor: 'pointer',
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral3,
    },
    'dot:selected': {
      height: DOT_SIZE,
      width: DOT_SIZE,
      backgroundColor: theme.colors.primary3,
      cursor: 'pointer',
      borderRadius: theme.borderRadius.rounded,
    },
    'dot:disabled': {
      cursor: 'auto',
    },
  })),
  optimumOnboarding: createPagerVariant(theme => ({
    dots: {
      marginBottom: 'auto',
    },
    'dot:selected': {
      backgroundColor: theme.colors.neutral1,
    },
    dot: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    footerWrapper: {
      flexDirection: 'column-reverse',
      height: '20svh',
      paddingTop: theme.spacing.value(1),
      paddingBottom: theme.spacing.value(4),
    },
  })),
}

StyleRegistry.registerVariants('Pager', PagerStyles)
