import { Navigation, Navigator } from '@codeleap/web'
import { NextRouter } from 'next/router'

export const routes = {
  // add a new route following the pattern of the others
  Home: '/',
  Profile: {
    Edit: 'profile/edit',
  },
  Auth: {
    Login: 'auth/login',
    Signup: 'auth/signup',
    ForgotPassword: 'auth/forgot',
    VerifyEmail: 'auth/verifyEmail',
  },
  About: 'about',
  Playground: 'playground',
  Components: {
    View: 'components/{{component}}',
    List: 'components',
    Navigation: {
      Home: 'components/Navigation',
      Page1: 'components/Navigation/page-1',
      Page2: 'components/Navigation/page-2/{{id}}'
    }
  },
  Crud: {
    Example: 'crud',
    View: 'crud/{{id}}'
  },
  Search: './search',
  Nutrition: './nutrition',
  Exercise: './exercise',
} as const

type NavigationContext = {
  router: NextRouter
  pathname: string
}

const navigator: Navigator<any,NavigationContext> = (path, options, ctx) => {
  if(ctx?.router) {
    ctx.router.push(path)
    return
  }

  throw new Error('Router not found in context')
}

export const navigation = new Navigation<{}, typeof routes, NavigationContext>(routes, navigator)
