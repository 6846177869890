export { default as LogoImage } from './codeleap_logo_black.png'
export { default as Placeholder } from './placeholder.png'
export { default as loadingAnimation } from '../images/loadingAnimation.json'
export { default as LogoTemplate } from './logo-template.png'
export { default as LogoTemplateMobile } from './logo-template-mobile.png'
export { default as Onboarding1 } from './onboarding1.png'
export { default as Onboarding2 } from './onboarding2.png'
export { default as RocketImage } from './rocket.jpeg'
export { default as HomeImage } from './home.png'
export { default as SignUp } from './SignUp.png'
export { default as EmptyBox } from './emptyBox.png'
export { default as MagnifyingGlass } from './magnifying_glass.png'
export { default as Exercise } from './exercise.png'
export { default as Nutrition } from './Nutrition.png'
export { default as ChatOnboarding } from './Chat-onboarding.png'
export { default as OptimumChatBg } from './optimum_chat_bg.png'
export { default as OptimumAvatar } from './optimum_avatar.png'
export { default as OptimumAvatar2 } from './optimum-avatar-2.png'
export { default as AssistantTailMessage } from './assistant_tail_message.png'
export { default as UsertTailMessage } from './user_tail_message.png'
export { default as HeaderLogo } from './header-logo.png'
export { default as OptimumCardBG } from './optimum-card-bg.png'
export { default as Format } from './format.png'
export { default as OptimumOnboarding1 } from './optimum_onboarding-1.png'
export { default as OptimumOnboarding2 } from './optimum_onboarding-2.png'
export { default as OptimumOnboarding3 } from './optimum_onboarding-3.png'
export { default as ConfettiAnimation } from '../confetti-animation.json'
export { default as FireworkAnimation } from '../firework-animation.json'
export * from './optimum_mood'
